import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import MainTemplate from 'components/templates/MainTemplate';
import { heading, title, metaKeywords, metaDescription } from 'constants/meta';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import EmployeeCard from './EmployeeCard';
import nikitin from './nikitin.jpg';
import nikitina from './nikitina.jpg';
import zagidullina from './zagidullina.jpg';
import mutigullina from './mutigullina.jpg';
import fedorova from './fedorova.jpg';

const Title = styled.h1`
  font-weight: normal;
  margin-top: 0.3em;
  margin-bottom: 0.7rem;
`;

const TeamPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.team}</title>
      <meta name="keywords" content={metaKeywords.team} />
      <meta name="description" content={metaDescription.team} />
    </Helmet>
    <Breadcrumbs
      links={[
        {
          link: '/contacts',
          text: 'Контакты',
        },
      ]}
    />
    <Title>{heading.team}</Title>
    <EmployeeCard
      name="Никитин Владимир Александрович"
      position="Директор компании «Золотая Камея»"
      phone="+7 (843) 25-35-900"
      email="volodya_nikitin@mail.ru"
      photo={nikitin}
    />
    <EmployeeCard
      name="Никитина Гульфия Габдуловна"
      position="Отдел камней и вставок"
      phone="8 800-700-17-89"
      email="info@gold-kamea.ru"
      photo={nikitina}
    />
    <EmployeeCard
      name="Загидуллина Лилия Шамилевна"
      position="Отдел одноразовых опок и восковок"
      phone="‎+7 903-387-72-21"
      photo={zagidullina}
    />
    <EmployeeCard
      name="Мутигуллина Гулия Габдуловна"
      position="Отдел ювелирного оборудования и инструмента"
      phone="8 800-700-17-89"
      photo={mutigullina}
    />
    <EmployeeCard
      name="Фёдорова Разиля Рануровна"
      position="Отдел логистики"
      phone="+7 962-550-46-22"
      photo={fedorova}
    />
  </MainTemplate>
);

export default TeamPage;
