import styled from 'styled-components';
import { Dl, Dt, Dd } from 'components/atoms/Dl';

const StyledDd = styled(Dd)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  border: 0.0625em solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  padding: 0.5em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}em) {
    flex-direction: row;
    padding: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  margin: -0.5em -0.5em 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}em) {
    flex: none;
    width: 45%;
    max-width: 20em;
    margin: -1em 1em -1em -1em;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  margin: 0 0 0.5rem 0;
  display: block;
`;

const EmployeeCard = ({ name, position, phone, email, photo }) => (
  <Wrapper>
    <ImageWrapper>
      <Image src={photo} alt={`${name} (${position})`} />
    </ImageWrapper>
    <div>
      <Title>{name}</Title>
      <Subtitle>{position}</Subtitle>
      <Dl>
        {phone && (
          <>
            <Dt>Телефон</Dt>
            <StyledDd>{phone}</StyledDd>
          </>
        )}
        {email && (
          <>
            <Dt>E-mail</Dt>
            <StyledDd>{email}</StyledDd>
          </>
        )}
      </Dl>
    </div>
  </Wrapper>
);

export default EmployeeCard;
